import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EventConfig from '../Pages/EventConfig';
import Home from '../Pages/Home';
import AllEvents from '../Pages/AllEvents';
import EventEdit from '../Pages/EventEdit';

const routes = [
    {
      path: '/',
      exact: true,
      component: Home,
    },
    {
      path: '/lives/new',
      exact: true,
      component: EventConfig,
    },
    {
      path: '/lives',
      exact: true,
      component: AllEvents,
    },
    {
      path: '/lives/:eventID/edit',
      exact: true,
      component: EventEdit,
    },
];

export default function Routes() {
    return (
      <Switch>
        {routes.map(({ path, exact, component: Component }) => (
          <Route key={path} path={path} exact={exact} component={Component} />
        ))}
      </Switch>
    );
}