import './App.scss';

import { ApolloProvider } from '@apollo/client/react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import client from './services';


function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
}

export default App;
