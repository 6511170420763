import React from "react";
import "./EventEdit.scss";

import { Redirect,useLocation } from 'react-router-dom';

import { gql, useApolloClient, useMutation, useLazyQuery } from '@apollo/client';
import { useParams } from "react-router";


import Card from "../../components/Card";
import LogoClimatempo from "../../components/LogoClimatempo";
import FormEvent from "../../components/FormEvent";
import Loading from "../../components/Loading";
import Error from '../../components/Error';


const GET_EVENT = gql`
  query getEvent($_id: String!) {
    event(_id: $_id) {
      _id
      title
      liveOn
      liveID
      description
      image
    }

  }
`;

const EDIT_EVENT = gql`
  mutation EditEvent(
    $_id: String!
    $title:String! 
    $description: String!
    $image: String!
    $liveID: String!
    $liveOn: Boolean!
  ){
    updateEvent(
      _id: $_id
      title:$title 
      description: $description
      image: $image
      liveID: $liveID
      liveOn: $liveOn
    ){
      _id
      title
      liveOn
      liveID
      description
      image
    }
  }
`;

const GET_EVENTS = gql`
  query getEvents {
    events {
      _id
      title
      liveOn
      description
    }
  }
`;

const EventEdit = () => {
    const client = useApolloClient();
    const location = useLocation();

    const { eventID } = useParams(); 

    const [event, setEvent] = React.useState(null);
  
    const [redirect, setRedirect] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [renderError, setRenderError] = React.useState(false);

    const [updateEvent, { loading }] = useMutation(EDIT_EVENT, {
      fetchPolicy: "network-only",
      update: (cache, {data : { updateEvent }}) => {
        setEvent(updateEvent)
      }
    })

    const onFinish = (values) => {
      const { title, description, liveID, image, liveOn,  } = values
      const { _id } = event;
      
      const { events } = client.readQuery({
        query: GET_EVENTS
      })

      if(values.liveOn && events.findIndex(e => e.liveOn) >= 0){
        setError({msg : "Já existe um evento com status ON!"});
        setRenderError(true);  
      } else {
        updateEvent({
          variables: { _id, title, description, liveID, liveOn: liveOn ? liveOn : false, image }
        })
      }

    }

    React.useEffect(() => {
      
      const data = client.readQuery({
        query: GET_EVENT,
        variables: {_id: eventID}
      })
      
      if(data && data.event) setEvent(data.event);
      else {
        setRedirect(true);
      }
    }, [])
    
    if(redirect){
      const { from } = location.state || { from: { pathname: '/lives' } };
      return <Redirect to={from} />;
    } else if(loading) {
      return <Loading />
    } else {
      return (
        <div className="event-new">
            <Card>
                <LogoClimatempo margin="_margin-b-0-h-auto-t-40" />
                {event ?
                  <FormEvent 
                      onFinish={(values) => onFinish(values)}
                      from={{pathname: "/lives"}}
                      event={event}
                  />               
                
                : 
                  <Loading />
                }
                <Error renderError={renderError} error={error} closeError={() => {
                  setRenderError(false)
                  setError(null)
                }}/> 
            </Card>
        </div>
      )
    }
}

export default EventEdit;