import React from 'react';
import "./EventConfig.scss"

import { Redirect, useLocation } from 'react-router-dom';
import { gql, useMutation, useLazyQuery ,useApolloClient} from '@apollo/client';

import Card from "../../components/Card";
import LogoClimatempo from "../../components/LogoClimatempo";
import FormEvent from "../../components/FormEvent";
import Loading from "../../components/Loading";

const AUTH_QUERY = gql `
    query ValidateSession($token: String!){
        validateSession(token: $token)
    }
`

const CREATE_EVENT_QUERY = gql `
    mutation CreateEvent(
        $title:String! 
        $description: String!
        $image: String!
        $liveID: String!
        $liveOn: Boolean!
    ){
        createEvent(title:$title, description: $description, image: $image, liveID: $liveID, liveOn: $liveOn){
            _id
            title
            liveOn
        }
    }
`;

const GET_EVENTS = gql`
  query getEvents {
    events {
      _id
      title
      liveOn
    }

  }
`;


export default function EventConfig() {
    const location = useLocation();
    const client = useApolloClient();

    const [auth, setAuth] = React.useState(false);
    const [authToken, setAuthToken] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    const [redirectLives, setRedirectLives] = React.useState(false);

    const [authSession] = useLazyQuery(AUTH_QUERY, {
        onCompleted: (data) => {
          if(data.validateSession) {
            setAuth(true)
          }
          else {
            localStorage.setItem("auth_token", null)
            setAuthToken(null)
            setAuth(false) 
            setRedirect(true)    
          }    
        }
    });
    
    const [createEvent, { loading }] = useMutation(CREATE_EVENT_QUERY, {
        fetchPolicy: "network-only",
        update: (cache, {data : { createEvent}}) => {
            const { events } = cache.readQuery({query: GET_EVENTS})
            cache.writeQuery({
                query: GET_EVENTS,
                data: { events: events.concat([createEvent]) }
            })
            setRedirectLives(true)
        }
      });

    const onFinish = (values) => {
        
        const { title, description, liveID, image, liveOn,  } = values
     
        createEvent({
            variables: { title, description, liveID, liveOn: liveOn ? liveOn : false, image }
        })
    }

    React.useEffect(() => {
        if(!authToken){
            const token = localStorage.getItem("auth_token");
        
            if(token) {
              setAuthToken(token);
              authSession({variables : { token }})
            } else setRedirect(true)
        } else {
            const data = client.readQuery({
                query: GET_EVENTS
            })
            
            if(!data || data.events.findIndex(e => e.liveOn) >= 0){
                setRedirectLives(true);
            }
        }
    }, [ authSession, authToken ])
    
    if(!authToken && !auth && redirect){
        const { from } = location.state || { from: { pathname: '/' } };
        return <Redirect to={from} />;
    } else if((authToken && !auth) || loading) {
        return <Loading />
    } if (redirectLives) {
        return <Redirect to={{pathname: "/lives"}} />
    } else {
        return (
            <div className="event-config">
                <Card>
                    <LogoClimatempo margin="_margin-b-0-h-auto-t-40" />
    
                    <FormEvent 
                        onFinish={(values) => onFinish(values)}
                        from={{pathname: "/lives"}}
                    />               
                </Card>
            </div>
        )
    }
}