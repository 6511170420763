import React from "react";
import "./FormEvent.scss";

import { Link } from 'react-router-dom';

import { Form, Input, Switch, Button } from 'antd';

const FormEvent = ({ onFinish, from, event }) => {
    const [checked, setChecked] = React.useState(event ? event.liveOn : false);

    return (
        <div className="form-event">
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                    offset: 2
                }}
                wrapperCol={{
                    span: 18,
                }}
                labelAlign="left"
                onFinish={onFinish}
                initialValues={{
                    "liveID": event ? event.liveID : null,
                    "title": event ? event.title : null,
                    "image": event ? event.image : null,
                    "description": event ? event.description : null,
                }}
            >
                <Form.Item
                    label="Evento"
                    name="liveOn"
                    labelCol={{span: 4, offset:2}}
                    wrapperCol={{span: 5}}
                >
                    <Switch checked={checked} onChange={setChecked} checkedChildren="ON" unCheckedChildren="OFF" />
                </Form.Item>
                <Form.Item
                    label="URL"
                    name="liveID"
                    rules={[
                    {
                        required: true,
                        message: "Por favor, informe o link da live!"
                    },{
                        pattern: /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                        message: 'Link inválido!'
                    }
                    ]}
                >
                    <Input placeholder="Informe o link da live" />
                </Form.Item>
                <Form.Item
                    label="Título"
                    name="title"
                    rules={[
                    {
                        required: true,
                        message: "Por favor, informe o título da live!"
                    },
                    ]}
                >
                    <Input placeholder="Informe o título da live" />
                </Form.Item>
                <Form.Item
                    label="Thumbnail"
                    name="image"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    rules={[
                    {
                        required: true,
                        message: "Por favor, informe o link da imagem!"
                    },
                    ]}
                >
                    <Input placeholder="Informe o Link da Thumbnail" />
                </Form.Item>
                <Form.Item
                    label="Descrição"
                    name="description"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    rules={[
                    {
                        required: true,
                        message: "Por favor, informe uma descrição"
                    },
                    ]}
                >
                    <Input.TextArea rows={4} placeholder="Informe o título da live" />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 6, span: 16
                    }}
                >
                    <Link to={from}>
                        <Button type="ghost">
                            Voltar
                        </Button>
                    </Link>
                    <Button type="primary" htmlType="submit" style={{ margin: '0 20px' }}>
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default FormEvent;