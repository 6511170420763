import React from "react";
import "./ListEvents.scss";

import InfiniteScroll from 'react-infinite-scroller';

import { List, Switch, Spin, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ListEvents = ({eventsList, changeStatus, handleGetEvent, deleteEvent}) => {
    const [loading, setLoading] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);

    const handleInfiniteOnLoad = () => {

    }

    return (
        <div className="infinite-container">
            {eventsList ? 
                (
                    
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={!loading && hasMore}
                useWindow={false}
            >
                <List
                    dataSource={eventsList}
                    renderItem={item => (
                    <List.Item key={item._id}>
                        <List.Item.Meta
                        title={<a onClick={() => handleGetEvent(item._id)}>{item.title}</a>}
                        />
                        <div>
                            <Button type="ghost" danger onClick={() => deleteEvent(item._id)} icon={<DeleteOutlined />} style={{marginRight: "5px"}} />
                            <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={item.liveOn} onChange={(check) => changeStatus(item._id, check)}/>
                        </div>
                    </List.Item>
                    )}
                >
                    {loading && hasMore && (
                    <div className="loading-container">
                        <Spin />
                    </div>
                    )}
                </List>
            </InfiniteScroll>
                )
            : null}
        </div>
    );
};

export default ListEvents;