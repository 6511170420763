import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';

import { Button } from "antd";
import { FileAddOutlined } from '@ant-design/icons';

import Card from "../../components/Card";
import LogoClimatempo from "../../components/LogoClimatempo";
import ListEvents from "../../components/ListEvents";
import Loading from "../../components/Loading";
import Error from '../../components/Error';


const GET_EVENTS = gql`
  query getEvents {
    events {
      _id
      title
      liveOn
      liveID
      description
      image
    }

  }
`;

const GET_EVENT = gql`
  query getEvent($_id: String!) {
    event(_id: $_id) {
      _id
      title
      liveOn
      liveID
      description
      image
    }

  }
`;

const CHANGE_STATUS_LIVE = gql `
  mutation ChangeLiveStatus($_id: String!, $status: Boolean!){
    changeLiveStatus(_id: $_id, status: $status ){
      _id
      title
      liveOn
    }
  }
`

const AUTH_QUERY = gql `
    query ValidateSession($token: String!){
        validateSession(token: $token)
    }
`

const DELETE_EVENT_QUERY = gql `
  mutation DeleteEvent($_id: String!){
    deleteEvent(_id: $_id)
  }
`

const AllEvents = () => {
  const location = useLocation();

  const [lives, setLives] = React.useState(null);
  const [auth, setAuth] = React.useState(false);
  const [authToken, setAuthToken] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [renderError, setRenderError] = React.useState(false);
  const [currentLive, setCurrentLive] = React.useState(null);
  const [renderEdit, setRenderEdit] = React.useState(false);
  const [renderNewEvent, setRenderNewEvent] = React.useState(false);

  const { loading } = useQuery(GET_EVENTS, {
      onCompleted: (data) => {
        setLives(data.events)
      }
  });

  const [changeStatusLive] = useMutation(CHANGE_STATUS_LIVE, {
    fetchPolicy: "network-only",
    update: (cache) => {
      const { events } = cache.readQuery({query: GET_EVENTS})
      setLives(events)
    }
  });

  const [deleteEvent] = useMutation(DELETE_EVENT_QUERY);

  const [authSession] = useLazyQuery(AUTH_QUERY, {
    onCompleted: (data) => {
      if(data.validateSession) {
        setAuth(true)
      }
      else {
        localStorage.setItem("auth_token", null)
        setAuthToken(null)
        setAuth(false) 
        setRedirect(true)    
      }    
    }
  });

  const [getEvent] = useLazyQuery(GET_EVENT, {
    onCompleted:(data) => {
      if(data.event){
        setRenderEdit(true);
        setCurrentLive(data.event);
      }
    }
  })
  
  const handleChangeStatusLive = (_id, status) => {
    if(status && lives.findIndex(e => e.liveOn) >= 0){
      setError({msg : "Já existe um evento com status ON!"});
      setRenderError(true);
    } else {
      changeStatusLive({variables: { _id, status }})
    }
  }

  const handleRedirectNewEvent = () => {
    if(lives.findIndex(e => e.liveOn) >= 0){
      setError({msg : "Já existe um evento com status ON!"});
      setRenderError(true);
    } else {
      setRenderNewEvent(true)
    }
  }

  const handleDeleteEvent = (_id) => {
    deleteEvent({ 
      variables: { _id }, 
      fetchPolicy: "network-only",
      update: (cache) => {
        cache.modify({
          fields: {
            events(oldEvents, { readField }){
              return oldEvents.filter(el => _id !== readField("_id", el))
            }
          }
        })
        const { events } = cache.readQuery({query: GET_EVENTS});
        
        setLives(events);
      },
    })
  }

  React.useEffect(() => {
    if(!authToken){
      const token = localStorage.getItem("auth_token");

      if(token) {
        setAuthToken(token);
        authSession({variables : { token }})
      } else setRedirect(true)
    }
  }, [ authSession, authToken ])

  if(!authToken && !auth && redirect){
    const { from } = location.state || { from: { pathname: '/' } };
    return <Redirect to={from} />;
  }else if(authToken && !auth) {
    return <Loading />
  } else if(renderEdit && currentLive) {
    const { from } = location.state || { from: { pathname: `/lives/${currentLive._id}/edit` } };
    return <Redirect to={from} />;
  } if(renderNewEvent) {
    const { from } = location.state || { from: { pathname: `/lives/new` } };
    return <Redirect to={from} />;
  }else {
    return (
        <Card>
            <LogoClimatempo margin="_margin-b-0-h-auto-t-40" />
            {loading ? 
              <Loading />
             : 
              <>
                <ListEvents eventsList={lives} 
                  changeStatus={(_id, status) => handleChangeStatusLive( _id, status ) }
                  handleGetEvent={(_id) => getEvent({ variables : { _id }})}
                  deleteEvent={(_id) => handleDeleteEvent(_id)}
                />
                <div style={{margin: "20px auto 0px"}}>
                  <Button onClick={handleRedirectNewEvent} type="primary" size="large" icon={<FileAddOutlined />}>Novo Evento</Button>
                </div>
              </>
             }

            <Error renderError={renderError} error={error} closeError={() => {
              setRenderError(false)
              setError(null)
            }}/>   
        </Card>
    );
  }  
};

export default AllEvents;