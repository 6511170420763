import React from 'react';
import "./Error.scss";

import { Icon } from '@iconify/react';

const Error = ({renderError, error, closeError}) => {
    if(renderError && error){
        return (
            <div className="common-error">
                <span className="close-error"><Icon icon="heroicons-solid:x" onClick={closeError} /></span>
                <b>Ops!</b> {error.msg}
            </div>
        )
    } else return null
}

export default Error;