import React from 'react';
import "./Home.scss";

import { Redirect, useLocation } from 'react-router-dom';

import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Form, Input, Button } from 'antd';

import Card from "../../components/Card";
import LogoClimatempo from "../../components/LogoClimatempo";
import Error from '../../components/Error';
import Loading from "../../components/Loading";

const LOGIN_MUTATION = gql `
  mutation Login($email: String! $password: String!){
    login(email: $email, password: $password ){
      token
    }
  }
`
const AUTH_QUERY = gql `
    query ValidateSession($token: String!){
        validateSession(token: $token)
    }
`

export default function Home() {
    const location = useLocation();

    const [error, setError] = React.useState(null);
    const [renderError, setRenderError] = React.useState(false);
    const [auth, setAuth] = React.useState(false);
    const [authToken, setAuthToken] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    
    const [authLogin] = useMutation(LOGIN_MUTATION, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            localStorage.setItem("auth_token", data.login.token)
            setAuth(true)
        },
        onError: (error) => {
            console.log(error)
            setError({msg: "Email ou senha incorretos!"})
            setRenderError(true);
        }
    });

    const [authSession] = useLazyQuery(AUTH_QUERY, {
        onCompleted: (data) => {
            if(data.validateSession) setAuth(true)
            else {
                setAuthToken(null)
                setAuth(false)    
            }    
        },
    });

    const handleLogin = (values) => {
        authLogin({variables: {
            email: values.email,
            password: values.password
        }})
    }
    React.useEffect(() => {
        if(!authToken){
            const token = localStorage.getItem("auth_token");
            if(token) {
                setAuthToken(token);
                authSession({variables : { token }})
            }
        }
        if(auth) setRedirect(true)
    }, [auth, authSession])
    
    if(redirect){
        const { from } = location.state || { from: { pathname: '/lives' } };
        return <Redirect to={from} />;
    } if(authToken) {
        return <Loading />
    } else {
        return (
            <div className="home-container">
                <Card>
                    <LogoClimatempo margin="_margin-b-0-h-auto-t-40" />
    
                    <Form
                        name="basic"
                        wrapperCol={{
                            span: 24,
                        }}
                        onFinish={handleLogin}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor informe seu email!"
                                },{
                                    pattern: /([a-zA-Z0-9]+)([{1}])?([a-zA-Z0-9]+)@climatempo.com.br/g,
                                    message: 'Email fora do domínio Climatempo!'
                                }
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
    
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor informe uma senha!"
                                }
                            ]}
                        >
                            <Input.Password placeholder="Senha" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                            span: 24,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Entrar
                            </Button>
                        </Form.Item>
                    </Form>
                    <Error renderError={renderError} error={error} closeError={() => setRenderError(false)}/>   
                </Card>
            </div>
        )
    }

}